import { useState } from 'react';

function Calculator() {
  const [value1, setValue1] = useState(4000);
  const [value2, setValue2] = useState(10);

  const handleSlider1Change = (event: { target: { value: string } }) => {
    const value = Math.round(parseInt(event.target.value) / 100) * 100;
    setValue1(value);
  };

  const handleSlider2Change = (event: { target: { value: string } }) => {
    const value = Math.round(parseInt(event.target.value) / 5) * 5;
    setValue2(value);
  };

  const [quantity, setQuantity] = useState(1);
  const [total, setTotal] = useState(500);

  const handleIncrease = () => {
    if (quantity < 10) {
      setQuantity(quantity + 1);
      setTotal(total + 500);
    }
  };

  const handleDecrease = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
      setTotal(total - 500);
    }
  };

  const quantityStyle = {
    transform: `translateX(${-115 + (quantity - 1) * 26}px)`,
  };

  const [quantityP, setQuantityP] = useState(1);
  const [totalP, setTotalP] = useState(5);

  const handleIncreasePercentage = () => {
    if (quantityP < 20) {
      setQuantityP(quantityP + 1);
      setTotalP(totalP + 5);
    }
  };

  const handleDecreasePercentage = () => {
    if (quantityP > 1) {
      setQuantityP(quantityP - 1);
      setTotalP(totalP - 5);
    }
  };

  const quantityStylePercentage = {
    transform: `translateX(${-115 + (quantityP - 1) * 12}px)`,
  };

  const monthlySavings = Math.round((value2 / 100) * value1);
  const annualSavings = monthlySavings * 12;

  return (
    <>
      <style>
        {`
          input[type="range"] {
            -webkit-appearance: none;
            appearance: none;
            width: 100%;
            height: 30px;
            background-color: transparent;
            border: none;
            outline: none;
            margin: 0;
            padding: 0;
            position: relative;
            z-index: 2;
          }

          input[type="range"]::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 25px;
            height: 45px;
            background-color: #B7E391;
            border: 1px solid #1B6300;
            border-radius: 7px;
            cursor: pointer;
            z-index: 3;
          }

          input[type="range"]:focus {
            outline: 2px solid #051700;
          }
        `}
      </style>

      <div role="main" className="aldo checkout xl:max-w-7xl mx-auto bg-primary text-white flex items-left lg:flex-row xsm:flex-col">
        <div className='flex flex-col lg:w-1/2 xsm:w-full lg:mb-20'>
          <div className='flex my-10 mt-20 xsm:px-4 md:px-16 lg:px-20 font-bold xsm:justify-items-start md:justify-items-center'>
            <h2 className='text-4xl'>Calculate how much <br /> you can save</h2>
          </div>
          <div className='xsm:pl-2 xsm:px-4 md:px-20'>
            <div className='flex justify-items-start my-8 xsm:px-4'>
              <img src={require('../../assets/Item.svg').default} className='w-[30px] mr-4' alt="Spending Item Icon" />
              <label htmlFor="slider1" className="title text-l">How much do you <br />spend monthly</label>
            </div>

            <div className='flex md:flex-row xsm:px-4 xsm:flex-col'>
              <p className='mr-10 font-bold text-xl w-[60px]'>${value1}</p>
              <div className="quantity min-w-[300px] bg-[#1B6300] rounded-lg flex items-center justify-between w-[300px] h-[35px]">
                <input
                  type="range"
                  min="500"
                  max="25000"
                  step="500"
                  value={value1}
                  onChange={handleSlider1Change}
                  className="w-full"
                  id="slider1"
                  aria-label="Set Monthly Spending"
                />
              </div>
            </div>
          </div>

          <div className='xsm:pl-2 md:px-20'>
            <div className='flex justify-items-start my-8 xsm:px-4'>
              <img src={require('../../assets/Filter.svg').default} className='w-[30px] mr-4' alt="Filter Icon" />
              <label htmlFor="slider2" className="title text-l">What's the %<br />that you want to save?</label>
            </div>

            <div className='flex md:flex-row xsm:flex-col xsm:px-4'>
              <p className='mr-10 font-bold text-xl w-[60px]'>{value2}%</p>
              <div className="quantity min-w-[300px] bg-[#1B6300] rounded-lg flex items-center justify-between w-[300px] h-[35px]">
                <input
                  type="range"
                  min="5"
                  max="75"
                  step="5"
                  value={value2}
                  onChange={handleSlider2Change}
                  className="w-full"
                  id="slider2"
                  aria-label="Set Percentage to Save"
                />
              </div>
            </div>
          </div>
        </div>

        <div className='flex-col lg:w-1/2 xsm:w-full text-[#051700] py-20 xsm:px-2 md:px-20'>
          <div className='text-[#051700] py-20 px-20 rounded-3xl bg-white'>
            <div className='flex flex-col pr-20'>
              <h2 className='text-2xl font-bold'>Annual <br /> Savings</h2>
              <p className='text-6xl text-primary font-bold'>${annualSavings}</p>
            </div>
            <div className='flex-row flex mt-10 bg-white'>
              <h6 className='text-sm'>Average <br /> Monthly Savings</h6>
              <p className='text-xl text-primary font-bold'>${monthlySavings}</p>
            </div>
          </div>
        </div>
      </div>

      <div className='checkout xl:max-w-7xl mx-auto bg-white text-primary flex items-left lg:flex-row xsm:flex-col'>
        <div className="xsm:mx-auto xsm:w-full xl:w-1/2">
          <img src={require('../../assets/whyguacappimg.png')} className='m-auto' alt="Guac App Screenshot" />
        </div>

        <div className="relative px-6 lg:px-8 xl:w-1/2 lg:w-full md:flex-col">
          <div className="mx-auto max-w-2xl py-8 sm:py-4 lg:py-4">
            <div className="text-left">
              <label htmlFor="easytouse" className='text-primary'>Why Guac App</label>
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-2xl lg:text-6xl xsm:mb-4 lg:mb-10">
                The complete savings app
              </h2>
              <ul className='flex flex-col justify-start xsm:text-sm md:text-lg'>
                <li className='flex flex-row mb-2 text-black'>
                  <img className='w-[20px] mr-2 max-h-[30px]' src={require('../../assets/checkmark.png')} alt="Checkmark" />
                  <p>No Hidden Fees</p>
                </li>
                <li className='flex flex-row mb-2 text-black'>
                  <img className='w-[20px] mr-2 max-h-[30px]' src={require('../../assets/checkmark.png')} alt="Checkmark" />
                  <p>Web App and Mobile App</p>
                </li>
                <li className='flex flex-row mb-2 text-black'>
                  <img className='w-[20px] mr-2 max-h-[30px]' src={require('../../assets/checkmark.png')} alt="Checkmark" />
                  <p>Highly Secured</p>
                </li>
                <li className='flex flex-row mb-2 text-black'>
                  <img className='w-[20px] mr-2 max-h-[30px]' src={require('../../assets/checkmark.png')} alt="Checkmark" />
                  <p>1 Month Free Trial of Premium</p>
                </li>
                <li className='flex flex-row mb-2 text-black'>
                  <img className='w-[20px] mr-2 max-h-[30px]' src={require('../../assets/checkmark.png')} alt="Checkmark" />
                  <p>FDIC<sup>i</sup> Insured up to $250,000
                  <span className='flex text-sm text-gray-500 break-words'>
                  Guac does not hold customer funds. Guac is a financial technology company, not a bank. Guac is not FDICi-insured. FDICi insurance only covers the failure of an insured bank.
                  </span>
                  </p>
                </li>
                <li className='flex flex-row mb-2 text-black'>
                  <img className='w-[20px] mr-2 max-h-[30px]' src={require('../../assets/checkmark.png')} alt="Checkmark" />
                  <p>Free Version of App</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Calculator;
